var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"entity-group-view"}},[_c('section',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"template-dashboard-view templates-list-view",attrs:{"element-loading-text":"Fetching Data...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"inner-navbar"},[_c('div',{staticClass:"top-strip"},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"d-flex align-center"},[_c('el-button',{staticClass:"px-10",on:{"click":_vm.backToEntityGroups}},[_c('i',{staticClass:"el-icon-back"})]),(_vm.entityGroupData)?_c('h4',{staticClass:"group-title h4 f-weight-500 pl-15"},[_c('span',{staticClass:"yale-blue"},[_vm._v(_vm._s(this.entityGroupData.name))])]):_vm._e()],1)])],1)],1),_c('div',[_c('el-divider',{staticClass:"mt-0 mb-0"}),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24,"xs":24,"sm":12,"lg":6}},[_c('el-card',{staticClass:"card-style",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Entities")]),_c('span',{staticClass:"float-right"},[(_vm.entityGroupData && _vm.entityGroupData.entities)?_c('span',[_vm._v(" ( "+_vm._s(_vm.entityGroupData.entities.length)+" ) ")]):_c('span',[_vm._v("( 0 )")])])]),(
                  _vm.entityGroupData &&
                  _vm.entityGroupData.entities &&
                  !_vm.entityGroupData.entities.length
                )?_c('el-empty',{attrs:{"image-size":50,"description":"No data"}}):_vm._l((_vm.entityGroupData.entities),function(entityId,index){return _c('el-tag',{key:index,staticClass:"text-center",staticStyle:{"margin":"0.2rem"},attrs:{"type":"info"}},[_vm._v(" "+_vm._s(entityId.name)+" ")])})],2)],1),_c('el-col',{attrs:{"span":24,"xs":24,"sm":12,"lg":6}},[_c('el-card',{staticClass:"card-style",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Form Templates")]),(
                    _vm.entityGroupData &&
                    _vm.entityGroupData.formTemplates &&
                    _vm.entityGroupData.formTemplates.length
                  )?_c('span',{staticClass:"float-right"},[_vm._v(" ( "+_vm._s(_vm.entityGroupData.formTemplates.length)+" )")]):_vm._e()]),(
                  _vm.entityGroupData &&
                  _vm.entityGroupData.formTemplates &&
                  !_vm.entityGroupData.formTemplates.length
                )?_c('el-empty',{attrs:{"image-size":50,"description":"No data"}}):_vm._l((_vm.entityGroupData.formTemplates),function(tempId,index){return _c('el-tag',{key:index,staticClass:"text-center",staticStyle:{"margin":"0.2rem"},attrs:{"type":"info"}},[_vm._v(" "+_vm._s(tempId.name)+" ")])})],2)],1),_c('el-col',{attrs:{"span":24,"xs":24,"sm":12,"lg":6}},[_c('el-card',{staticClass:"card-style",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Global Variable")]),(
                    _vm.entityGroupData &&
                    _vm.entityGroupData.globalVariables &&
                    _vm.entityGroupData.globalVariables.length
                  )?_c('span',{staticClass:"float-right"},[_vm._v(" ( "+_vm._s(_vm.entityGroupData.globalVariables.length)+" )")]):_vm._e()]),(
                  _vm.entityGroupData &&
                  _vm.entityGroupData.globalVariables &&
                  !_vm.entityGroupData.globalVariables.length
                )?_c('el-empty',{attrs:{"image-size":50,"description":"No data"}}):_vm._l((_vm.entityGroupData.globalVariables),function(variable,index){return _c('el-tag',{key:index,staticClass:"text-center",staticStyle:{"margin":"0.2rem"},attrs:{"type":"info"}},[_vm._v(" "+_vm._s(variable.label)+" ")])})],2)],1),_c('el-col',{attrs:{"span":24,"xs":24,"sm":12,"lg":6}},[_c('el-card',{staticClass:"card-style",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Doc Templates")]),(
                    _vm.entityGroupData &&
                    _vm.entityGroupData.docTemplates &&
                    _vm.entityGroupData.docTemplates.length
                  )?_c('span',{staticClass:"float-right"},[_vm._v(" ( "+_vm._s(_vm.entityGroupData.docTemplates.length)+" )")]):_vm._e()]),(
                  _vm.entityGroupData &&
                  _vm.entityGroupData.docTemplates &&
                  !_vm.entityGroupData.docTemplates.length
                )?_c('el-empty',{attrs:{"image-size":50,"description":"No data"}}):_vm._l((_vm.entityGroupData.docTemplates),function(docTemplate,index){return _c('el-tag',{key:index,staticClass:"text-center",staticStyle:{"margin":"0.2rem"},attrs:{"type":"info"}},[_vm._v(" "+_vm._s(docTemplate.title)+" ")])})],2)],1),_c('el-col',{attrs:{"span":24,"xs":24,"sm":12,"lg":6}},[_c('el-card',{staticClass:"card-style",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Workflows")]),(
                    _vm.entityGroupData &&
                    _vm.entityGroupData.workflows &&
                    _vm.entityGroupData.workflows.length
                  )?_c('span',{staticClass:"float-right"},[_vm._v(" ( "+_vm._s(_vm.entityGroupData.workflows.length)+" )")]):_vm._e()]),(
                  _vm.entityGroupData &&
                  _vm.entityGroupData.workflows &&
                  !_vm.entityGroupData.workflows.length
                )?_c('el-empty',{attrs:{"image-size":50,"description":"No data"}}):_vm._l((_vm.entityGroupData.workflows),function(workflow,index){return _c('el-tag',{key:index,staticClass:"text-center",staticStyle:{"margin":"0.2rem"},attrs:{"type":"info"}},[_vm._v(" "+_vm._s(workflow.name)+" ")])})],2)],1),_c('el-col',{attrs:{"span":24,"xs":24,"sm":12,"lg":6}},[_c('el-card',{staticClass:"card-style",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Form Builder")]),(
                    _vm.entityGroupData &&
                    _vm.entityGroupData.formbuilders &&
                    _vm.entityGroupData.formbuilders.length
                  )?_c('span',{staticClass:"float-right"},[_vm._v(" ( "+_vm._s(_vm.entityGroupData.formbuilders.length)+" )")]):_vm._e()]),(
                  _vm.entityGroupData &&
                  _vm.entityGroupData.formbuilders &&
                  !_vm.entityGroupData.formbuilders.length
                )?_c('el-empty',{attrs:{"image-size":50,"description":"No data"}}):_vm._l((_vm.entityGroupData.formbuilders),function(formbuilders,index){return _c('el-tag',{key:index,staticClass:"text-center",staticStyle:{"margin":"0.2rem"},attrs:{"type":"info"}},[_vm._v(" "+_vm._s(formbuilders.name)+" ")])})],2)],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }